//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { smsPage } from '@/api/modular/system/smsManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  components: {
    XCard,
    STable
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '手機號',
          dataIndex: 'phoneNumbers'
        },
        {
          title: '短信驗證碼',
          dataIndex: 'validateCode'
        },
        {
          title: '短信模板ID',
          dataIndex: 'templateCode'
        },
        {
          title: '發送狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '來源',
          dataIndex: 'source',
          scopedSlots: { customRender: 'source' }
        },
        {
          title: '失效時間',
          dataIndex: 'invalidTime'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return smsPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusDictTypeDropDown: [],
      sourceDictTypeDropDown: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
  },
  methods: {
    sourceFilter (source) {
      // eslint-disable-next-line eqeqeq
      const values = this.sourceDictTypeDropDown.filter(item => item.code == source)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'send_type' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'sms_send_source' }).then((res) => {
        this.sourceDictTypeDropDown = res.data
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
